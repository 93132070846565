<template>
    <b-dropdown id="dropdown-select" v-bind="dropdownProps" @click="toggleDropdown">
        <template #button-content>
            {{ selectedLabel }}
        </template>

        <b-dropdown-item
            v-for="option in options"
            :key="option.value"
            :active="option.value === selected"
            @click="selectOption(option.value)"
        >
            {{ option.text }}
        </b-dropdown-item>
    </b-dropdown>
</template>

<script>
export default {
    name: 'DropdownSelect',
    props: {
        options: {
            type: Array,
            required: true,
            default: () => [],
        },
        value: {
            required: true,
        },
        dropdownProps: {
            type: Object,
            default: () => ({}),
        },
    },
    computed: {
        selectedLabel() {
            const selectedOption = this.options.find((option) => option.value === this.value);
            return selectedOption ? selectedOption.text : this.t('selectOption');
        },
        selected() {
            return this.value;
        },
    },
    methods: {
        selectOption(value) {
            this.$emit('input', value);
        },
        toggleDropdown() {
            this.$emit('dropdown-toggled');
        },
    },
};
</script>

<style scoped>
/* Add custom styling here if needed */
</style>
